html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  /*background-image: url('./assets/images/Background.png'); Replace with your image path */
  background-color: blue;
  background-size: cover; /* Ensure the image covers the entire background */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
  background-attachment: fixed; /* Make the background image fixed when scrolling */
}

.Video {
  display: flex;
  z-index: 1;
  background-color: transparent;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #555;
}